import { createContext, useState, useEffect } from "react";
import axios from "../api/axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLog, setIsLog] = useState(false);
  const [userData, setUserData] = useState(null);

  const refreshUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/user/me", headers);
      if (response.status == 200) {
        setUserData(response.data);
      }
      return response.status;
    } catch (err) {
      console.log(err);
      setUserData(null);
    }
  };

  const loginUser = async (email, password) => {
    try {
      const data = {
        email: email,
        password: password,
      };
      const response = await axios.post("/auth/login", data);
      if (response.status == 201) {
        localStorage.setItem("token", response.data.token);
        try {
          const headers = {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${response.data.token}`,
            },
          };
          const userResponse = await axios.get("/user/me", headers);
          if (userResponse.status == 200) {
            setUserData(userResponse.data);
            setIsLog(true);
        return userResponse.status;
          }
          
        } catch (err) {
          console.log(err)
        }
        
      }
    } catch (err) {
      console.log(err);
    }
  };

  const logoutUser = async () => {
    setIsLog(false);
    setUserData(null);
    localStorage.setItem("token", "");
  };

  const addToWishlist = async (productId) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      const body = {}
      const response = await axios.post(`/user/wishlist/${productId}`, body, headers);
      if (response.status == 200) {
        refreshUserData();
      }
      return response.status;
    } catch (err) {
      console.log(err);
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.delete(
        `/user/wishlist/${productId}`,
        headers
      );
      if (response.status == 200) {
        refreshUserData();
      }
      return response.status;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLog,
        setIsLog,
        userData,
        refreshUserData,
        loginUser,
        logoutUser,
        addToWishlist,
        removeFromWishlist
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
